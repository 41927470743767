/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type { Router } from '@ember/routing';
import type Store from '@ember-data/store';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { post } from 'embercom/lib/ajax';
import type { Email } from 'embercom/components/multi-email-input';
import type { TaskGenerator } from 'ember-concurrency';

export class TeammatesInviter {
  app: any;
  router: Router;
  store: Store;
  guideLibraryService: GuideLibraryService;

  constructor({
    app,
    router,
    store,
    guideLibraryService,
  }: {
    app: any;
    router: Router;
    store: Store;
    guideLibraryService: GuideLibraryService;
  }) {
    this.app = app;
    this.router = router;
    this.store = store;
    this.guideLibraryService = guideLibraryService;
  }

  @tracked allEmails: Email[] = [];
  @tracked partialInput = '';

  get validEmails() {
    return this.allEmails.filter((email) => !email.error);
  }

  get sendInviteButtonDisabled() {
    let invalidEmails = this.allEmails.filter((email) => !!email.error);
    return (
      (!this.allEmails.length && !this.partialInput) ||
      invalidEmails.length !== 0 ||
      !this.app.invitingPermitted
    );
  }

  @action
  setPartialInput(string: string) {
    this.partialInput = string;
  }

  @action
  onEmailUpdate(emails: Email[]) {
    this.allEmails = emails;
  }

  @action
  navigateToInvitePage() {
    this.router.transitionTo('apps.app.settings.workspace.teammates.invite.new', {
      queryParams: {
        emails:
          this.validEmails.length > 0
            ? this.validEmails.map((validEmail) => validEmail.value).join(',')
            : undefined,
      },
    });
  }

  @action async performInvite(roleId?: string) {
    await taskFor(this.inviteTeammate).perform(roleId);

    if (this.guideLibraryService.canUseGuideLibraryService) {
      await this.guideLibraryService.markStepCompleted(
        'guide_library_foundational_steps_invite_teammates',
      );
    }
  }

  @task({ drop: true }) *inviteTeammate(roleId?: string): TaskGenerator<void> {
    let permissions = this.createPermissionsObject(roleId);

    let invitees = this.validEmails.map((email) => {
      return {
        email: email.value,
        app_id: this.app.id,
        seat_types: ['core'],
        ...permissions,
      };
    });

    if (invitees.length === 0) {
      return;
    }

    let response = yield post('/ember/invite_admin_groups', {
      invitees,
      app_id: this.app.id,
    });

    response.forEach((invite: any) => {
      this.store.pushPayload({ 'invited-admin': invite });
    });
  }

  private createPermissionsObject(roleId?: string) {
    let permissionsObject = this.store.createRecord('permission', {
      app_id: this.app.id,
      ...(roleId ? { role_id: roleId } : {}),
    });

    permissionsObject.setAllPermissions(true);
    return permissionsObject.serialize();
  }
}
