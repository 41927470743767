/* RESPONSIBLE TEAM: team-tickets-1 */

import PredicateGroup, {
  type Predicate,
  type SimplePredicate,
} from 'embercom/objects/inbox/search/predicate-group';
import { type TicketCategory } from 'embercom/objects/inbox/ticket';
import { type SortParams } from 'embercom/services/inbox-api';

interface TrackerTicketQueryParams {
  query: string;
  sortParams: SortParams;
  count: number;
  predicates: {
    predicates: Predicate[];
  };
  fields: string[];
  searchSource: string;
}

export function trackerTicketQueryBuilder(
  ticketCategory: TicketCategory,
  query: string,
): TrackerTicketQueryParams {
  let predicates: Predicate[] = [
    {
      identifier: 'ticket-category',
      type: 'string',
      attribute: 'ticket_category',
      comparison: 'eq',
      value: ticketCategory,
    },
    {
      identifier: 'ticket-type',
      type: 'integer',
      attribute: 'ticket_type_id',
      comparison: 'known',
      value: null,
    },
  ];

  if (query) {
    let ticketTitlePredicate: SimplePredicate = {
      identifier: 'ticket-title',
      type: 'ticket_text_field',
      attribute: 'ticket_title_downcased.full_words',
      comparison: 'contains',
      value: query,
    };
    let ticketDescriptionPredicate: SimplePredicate = {
      identifier: 'ticket-description',
      type: 'ticket_text_field',
      attribute: 'ticket_description_downcased.full_words',
      comparison: 'contains',
      value: query,
    };
    let filterTextPredicates: SimplePredicate[] = [
      ticketTitlePredicate,
      ticketDescriptionPredicate,
    ];
    if (!isNaN(parseInt(query, 10))) {
      let ticketIdPredicate: SimplePredicate = {
        identifier: 'ticket-id',
        type: 'id',
        attribute: 'ticket_id',
        comparison: 'eq',
        value: query,
      };
      filterTextPredicates.push(ticketIdPredicate);
    }

    predicates.push({
      identifier: 'ticket-title-description',
      type: 'or',
      predicates: filterTextPredicates,
    });
  }

  let predicatesGroup = new PredicateGroup(predicates);

  return {
    query,
    sortParams: {
      sort_field: 'sorting_updated_at',
      sort_direction: 'desc',
    },
    count: 15,
    predicates: { predicates: predicatesGroup.predicates },
    fields: [
      'ticket_type',
      'ticket_state',
      'linked_conversation_ids',
      'linked_customer_ticket_ids',
      'ticket_id',
      'ticket_custom_state_id',
    ],
    searchSource: 'tickets-command-k',
  };
}
